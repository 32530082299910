<template>
  <div>
    <b-tabs v-model="activeTab" type="is-boxed">
      <b-tab-item label="CMS"><CMSEdit :channel-list.sync="channelList"/></b-tab-item>
      <b-tab-item label="Спектры скорости"><VelEdit :agent-id="id" :channels="channelList" /></b-tab-item>
      <b-tab-item label="Спектры ускорения"><AccEdit :agent-id="id" :channels="channelList" /></b-tab-item>
      <b-tab-item label="Спектры огибающей"><EnvEdit :agent-id="id" :channels="channelList" /></b-tab-item>
      <b-tab-item label="Настройка температуры"><TemperatureEdit :agent-id="id" :channels="channelList" :is-active="activeTab === 4" /></b-tab-item>
      <b-tab-item label="Watchdogs"><WatchdogEdit :agent-id="id" :channels="channelList" :is-active="activeTab === 5" /></b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import CMSEdit from "@/components/CMS/Sub-component/CMSEdit.vue"
import VelEdit from "@/components/CMS/Sub-component/VelEdit.vue"
import AccEdit from "@/components/CMS/Sub-component/AccEdit.vue"
import EnvEdit from "@/components/CMS/Sub-component/EnvEdit.vue"
import TemperatureEdit from "@/components/CMS/Sub-component/Temperature/TemperatureEdit.vue"
import WatchdogEdit from "@/components/CMS/Sub-component/Watchdog/WatchdogEdit.vue"

export default {
  components: { WatchdogEdit, TemperatureEdit, EnvEdit, AccEdit, VelEdit, CMSEdit },
  props: ['id'],
  data() {
    return {
      channelList: [],
      activeTab: 0
    };
  },
};
</script>
