<script>
export default {
  name: "WatchdogTable",
  props: {
    data: Array,
    loading: Boolean,
    hideModuleColumn: Boolean
  },
  data() {
    return {

    }
  },
  methods: {
    formatDate(date) {
      if(!date) return '-'
      return new Date(date).toLocaleString()
    }
  }
}
</script>

<template>
  <b-table
    :data="data"
    :loading="loading"
    hoverable
    striped
  >
    <b-table-column v-if="!hideModuleColumn" field="order" label="Модуль" v-slot="props">
      {{ props.row.order }}
    </b-table-column>
    <b-table-column field="arrived_at" label="Данные пришли" v-slot="props">
      {{ props.row.arrived_at }}
    </b-table-column>
    <b-table-column field="date" label="Последний перезапуск" v-slot="props">
      {{ props.row.restarted_at || '-' }}
    </b-table-column>
    <b-table-column field="code" label="Последний код" v-slot="props">
      {{ props.row.code || '-' }}
    </b-table-column>
    <b-table-column field="msg" label="Последнее сообщение" v-slot="props" width="40%">
      <b-tooltip
        position="is-top"
        square
        type="is-primary is-light"
      >
        <div class="is-ellipsis">{{ props.row.msg || '-' }}</div>
        <template #content>
          <pre>{{ props.row.msg || '-' }}</pre>
        </template>
      </b-tooltip>
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">{{ loading ? 'Загрузка...' : 'Нет данных' }}</div>
    </template>
  </b-table>
</template>

<style lang="scss">
.clickable-table {
  tbody > tr {
    cursor: pointer;
  }

  td, th {
    vertical-align: middle;
  }
}
</style>
