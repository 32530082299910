<script>
const FORM_INITIAL = {
  name: '',
  agent_id: null,
  color: null,
  limit: 0,
}

const COLORS = [
  { label: "Синий", value: "has-text-info" },
  { label: "Фиолетовый", value: "has-text-primary" },
  { label: "Зеленый", value: "has-text-success" },
  { label: "Желтый", value: "has-text-warning" },
  { label: "Красный", value: "has-text-danger" },
]

export default {
  name: "TemperatureSaveDialog",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
    temperature: Object,
    names: Array
  },
  data() {
    return {
      form: { ...FORM_INITIAL },
      errors: {},
      colors: COLORS,
      isCreating: false
    }
  },
  computed: {
    isEdit() {
      if(!this.temperature) return false
      return Object.keys(this.temperature).length > 0
    }
  },
  watch: {
    temperature(newValue) {
      if(newValue) Object.assign(this.form, newValue)
    }
  },
  methods: {
    async saveTemperature() {
      this.errors = {}
      this.isCreating = true
      try {
        if(!this.isEdit) await this.$axios.post('admin/temperature-configs', { ...this.form, agent_id: this.agentId })
        else await this.$axios.put('admin/temperature-configs/' + this.temperature.id, { ...this.form, agent_id: this.agentId })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.errors = e.error?.response?.data?.errors ?? {}
        throw e
      } finally {
        this.isCreating = false
      }
    },
    closeDialog() {
      Object.assign(this.form, { ...FORM_INITIAL })
      this.$emit('update:isVisible', false)
      this.$emit('update:temperature', {})
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-3">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-half">
            <b-field label="Наименование" :type="!!errors.name ? 'is-danger' : ''" :message="errors.name && errors.name[0] || ''">
              <b-select
                placeholder="Наименование"
                v-model="form.name"
                expanded
              >
                <option
                  v-for="(name, nameIdx) in names"
                  :value="name"
                  :key="'temp-name__' + nameIdx"
                >
                  {{ name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Цвет" :type="!!errors.color ? 'is-danger' : ''" :message="errors.mark && errors.mark[0] || ''">
              <b-select
                placeholder="Цвет"
                v-model="form.color"
                expanded
              >
                <option
                  v-for="(color, idx) in colors"
                  :value="color.value"
                  :key="idx"
                >
                  {{ color.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Пороговое знвчение" :type="!!errors.limit ? 'is-danger' : ''" :message="errors.limit && errors.limit[0] || ''">
              <b-numberinput v-model="form.limit" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCreating"
              @click="closeDialog"
            />
            <b-button
              :label="isEdit ? 'Сохранить' : 'Добавить'"
              class="is-info"
              type="submit"
              :loading="isCreating"
              @click="saveTemperature"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
