<script>
import WatchdogTable from "@/components/CMS/Sub-component/Watchdog/WatchdogTable.vue"

export default {
  name: "WatchdogEdit",
  components: { WatchdogTable },
  props: {
    agentId: [Number, String],
    isActive: Boolean,
  },
  data() {
    return {
      isLoading: false,
      dialogIsVisible: false,
      data: {},
      loadingWatchdogs: []
    }
  },
  watch: {
    isActive(newValue) {
      if (newValue) this.getWatchdogs()
    },
  },
  methods: {
    async getWatchdogs() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/watchdogs`)
        this.data = data.result || {}
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    async restartWatchdog(type) {
      this.loadingWatchdogs.push(type)
      try {
        await this.$axios.post(`admin/agents/${this.agentId}/watchdogs`, { type })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loadingWatchdogs = this.loadingWatchdogs.filter(wd => wd !== type)
      }
    },
    getKeyLabel(key) {
      return {
        "trends": "Тренды",
        "messages": "Сообщения",
        "temperatures": "Температуры",
        "spectrums": "Спектры",
      }[key] || ""
    },
  },
}
</script>

<template>
  <div class="has-background-white p-5 is-relative" style="min-height: 100px">
    <b-loading v-model="isLoading" :is-full-page="false" />
    <div v-for="(value, key) in data" :key="key" class="mb-4">
      <div class="is-flex is-justify-content-space-between is-align-items-center mb-5">
        <h2 class="is-size-5 has-text-weight-semibold">{{ getKeyLabel(key) }}</h2>
        <b-button
          type="is-info"
          size="is-small"
          :loading="loadingWatchdogs.includes(key)"
          @click="restartWatchdog(key)"
        >Restart
        </b-button>
      </div>
      <WatchdogTable
        :hide-module-column="key === 'temperatures'"
        :data="value"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
