<script>
import MessageList from "@/components/CMS/CMSDetail/MessageList.vue"
import SelectTypeDialog from "@/components/CMS/Sub-component/SelectTypeDialog.vue"
import EnvTable from "@/components/CMS/Sub-component/EnvTable.vue"
import MessageTypeTable from "@/components/CMS/Sub-component/MessageTypeTable.vue"

export default {
  name: "EnvEdit",
  components: { MessageTypeTable, EnvTable, SelectTypeDialog, MessageList },
  props: {
    agentId: [Number, String],
    channels: Array,
  },
  data() {
    return {
      types: [],
      selectedTypes: [],
      editableItem: undefined,
      loadEnvList: false,
      bearingType: undefined,
      createDialogIsVisible: false,
      selectedChannel: ''
    }
  },
  mounted() {
    this.getBearingType()
    this.getSelectedBearingType()
  },
  methods: {
    async getBearingType() {
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/bearing`)
        this.bearingType = data?.data?.[0]?.id
        // await this.getTypes()
        this.loadEnvList = true
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getSelectedBearingType() {
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/bearings`)
        this.selectedTypes = data?.data || []
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getTypes() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/bearings`)
        this.types = data.data || []
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    onRowClick(event) {
      this.editableItem = event
      this.createDialogIsVisible = true
    },
    selectTypeDialog() {
      this.createDialogIsVisible = true
    },
    onRefresh() {
      this.loadEnvList = false
      this.getBearingType()
      this.getSelectedBearingType()
    },
    // async getEnvs() {
    //   try {
    //     const { data } = await this.$axios.get(`agents/${this.agentId}/message-dependent?type=env`)
    //     console.log(data)
    //   } catch (e) {
    //     console.error(e)
    //     throw e
    //   }
    // }
  },
}
</script>

<template>
  <div class="has-background-white p-5">
    <SelectTypeDialog
      :is-visible.sync="createDialogIsVisible"
      :env.sync="editableItem"
      :types="types"
      :channels="channels"
      :agent-id="agentId"
      @refresh="onRefresh"
    />
    <div class="mb-6">
      <div class="is-flex is-align-items-center is-justify-content-space-between mb-4">
        <p class="has-text-weight-semibold is-size-5 mb-3">Типы подшипников</p>
        <b-button
          label="Добавить"
          type="is-info"
          @click="selectTypeDialog"
        />
      </div>
      <MessageTypeTable :data="selectedTypes" :loading="false" show-only @rowClick="onRowClick" />
    </div>
<!--    <div class="is-flex" style="gap: 1rem">-->
<!--      <b-field class="is-flex-grow-1">-->
<!--        <b-select v-model="bearingType" class="bearing-type" placeholder="Не выбран" expanded disabled>-->
<!--          <option-->
<!--            v-for="option in types"-->
<!--            :value="option.id"-->
<!--            :key="option.id"-->
<!--          >-->
<!--            {{ option.manufacturer }} - {{ option.mark }}-->
<!--          </option>-->
<!--        </b-select>-->
<!--      </b-field>-->
<!--      <b-button-->
<!--        label="Добавить"-->
<!--        type="is-info"-->
<!--        @click="selectTypeDialog"-->
<!--      />-->
<!--    </div>-->
    <EnvTable
      :agent-id="agentId"
      :load-data="loadEnvList"
      title="Зависящие от скорости значения"
      type="env"
      message-type="dependent"
      @select="selectedChannel = $event"
    />
    <MessageList
      :agent-id="agentId"
      :channels="channels"
      :channel="selectedChannel"
      title="Общие пороговые ограничения"
      type="env"
      message-type="general"
    />
  </div>
</template>

<style scoped>

</style>
