<script>
const COLORS = [
  { label: "Синий", value: "has-text-info" },
  { label: "Фиолетовый", value: "has-text-primary" },
  { label: "Зеленый", value: "has-text-success" },
  { label: "Желтый", value: "has-text-warning" },
  { label: "Красный", value: "has-text-danger" },
]

export default {
  name: "TemperatureTable",
  props: {
    data: Array,
    loading: Boolean,
    showOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { field: "name", label: "Наименование", },
        { field: "color", label: "Цвет", },
        { field: "limit", label: "Пороговое знвчение", },
      ],
    }
  },
  methods: {
    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Удаление',
        message: 'Вы действительно хотите удалить?',
        confirmText: 'Удалить',
        cancelText: 'Отмена',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('delete', id)
      })
    },
    getColorLabel(value) {
      return COLORS.find(clr => clr.value === value)?.label ?? '-'
    }
  }
}
</script>

<template>
  <b-table
    :data="data"
    :loading="loading"
    hoverable
    striped
    class="clickable-table"
    @click="$emit('rowClick', $event)"
  >
    <b-table-column field="name" label="Наименование" v-slot="props">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column field="color" label="Цвет" v-slot="props">
      <div :class="[props.row.color]" class="has-text-weight-semibold">|||||||||||||||||</div>
    </b-table-column>
    <b-table-column field="limit" label="Пороговое знвчение" v-slot="props">
      {{ props.row.limit }}
    </b-table-column>
<!--    <b-table-column-->
<!--      v-for="column in columns"-->
<!--      :key="column.field"-->
<!--      :field="column.field"-->
<!--      :label="column.label"-->
<!--      v-slot="props"-->
<!--    >-->
<!--      <p-->
<!--        :class="[column.field === 'text' && 'is-ellipsis']"-->
<!--        :title="props.row[column.field]"-->
<!--      >{{ props.row[column.field] }}</p>-->
<!--    </b-table-column>-->
    <!--    <b-table-column label="Действия" v-slot="props">-->
    <!--      <div>-->
    <!--        <ConfirmAction-->
    <!--          @confirm="$emit('delete', props.row.id)"-->
    <!--        />-->
    <!--        &lt;!&ndash;          <b-button type="is-danger is-light" icon-right="trash" @click.stop=""/>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </b-table-column>-->
    <b-table-column
      v-if="!showOnly"
      :label="'Действия'"
      centered
      v-slot="props"
    >
      <b-button
        class="is-danger"
        icon-right="trash"
        @click.stop.prevent="confirmDelete(props.row.id)"
      />
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">{{ loading ? 'Загрузка...' : 'Нет данных' }}</div>
    </template>
  </b-table>
</template>

<style lang="scss">
.clickable-table {
  tbody > tr {
    cursor: pointer;
  }

  td, th {
    vertical-align: middle;
  }
}
</style>
